import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default class Viewer {
  constructor(threejsApp, container) {
    this.threejsApp = threejsApp;
    this.container = container;
    this.typeCamera = 'camera';
    this.init();
  }
  init() {
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      20,
      this.container.offsetWidth / this.container.offsetHeight,
      1,
      10000
    );

    this.camera.position.set(20, 8, -8);

    this.cameraOrt = null;
    this.cameraOrt = new THREE.OrthographicCamera(-20, 20, 20, -20, 0.1, 10000);
    this.cameraOrt.position.set(0, 10, 0);
    this.cameraOrt.lookAt(0, 0, 0);
    this.cameraOrt.rotation.z += Math.PI / 2;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.container.offsetWidth,
      this.container.offsetHeight
    );
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    // №1
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.4;

    this.renderer.shadowMap.enabled = true;
    this.container.appendChild(this.renderer.domElement);
    this.initLight();
    this.initControls();
    window.addEventListener('resize', this.onWindowResize.bind(this));
    this.animate();
  }

  initLight() {

    // №0
    // const hLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    // hLight.position.set(10, 100, 10);
    // this.scene.add(hLight);

    // №1
    const hLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hLight.position.set(10, 100, 10);
    this.scene.add(hLight);

    // №2
    // // const ambientLight = new THREE.AmbientLight(0xffffff);
    // // this.scene.add(ambientLight);
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2);
    // directionalLight.position.set(5, 7, 3);
    // this.scene.add(directionalLight);
    // const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.2);
    // this.scene.add(hemisphereLight);

  }

  initControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.target.set(0, 0, 0);
    this.controls.enablePan = false;
    this.controls.minDistance = 15;
    this.controls.maxDistance = 50;
    this.controls.minPolarAngle = 1;
    this.controls.maxPolarAngle = 1.4;
  }

  onWindowResize() {
    if (this.typeCamera === 'camera') {
      this.camera.aspect =
        this.container.offsetWidth / this.container.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.offsetWidth,
        this.container.offsetHeight
      );
    }
  }

  animate() {
    requestAnimationFrame(() => {
      this.animate();
    });
    TWEEN.update();
    this.controls?.update();
    this?.threejsApp?.updateBtnRoof();

    this?.threejsApp?.updatetBtnZipMenu();
    this?.threejsApp?.updatetBtnZipRoofMenu();
    this?.threejsApp?.updatetBtnWindow1Menu();
    this?.threejsApp?.updatetBtnWindow2Menu();
    this?.threejsApp?.updatetBtnLamellenMenu();
    // console.log(this?.threejsApp?.current?.name);
    if (this.typeCamera === 'camera') {
      this.renderer.render(this.scene, this.camera);
    }

    if (this.typeCamera === 'cameraOrt') {
      this.renderer.render(this.scene, this.cameraOrt);
    }
  }
}
